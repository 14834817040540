<template>
  <v-container fluid>
    <template>
      <v-tabs v-model="currentSubTab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>

        <v-tab v-for="(item, index) in tabs" :key="index">
          {{ item.title }}
        </v-tab>
      </v-tabs>
    </template>

    <v-tabs-items v-model="currentSubTab">
      <v-tab-item v-for="(item, index) in tabs" :key="index">
        <v-card class="my-4" flat>
          <component
            v-if="currentTabName === 'maintenance'"
            :is="item.component"
            v-bind="getComponentProps(item)"
            :key="item.dashboardId"
          ></component>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
export default {
  name: 'Maintenance',

  components: {
    DashboardSP: () => import('@/components/administration/service_point/dashboard/DashboardSP'),
  },

  props: {
    currentTab: {
      type: Number,
      required: false,
      default: null,
    },
    currentTabName: {
      type: String,
      required: false,
      default: null,
    },
  },

  data: () => {
    return {
      editedItem: null,
      currentSubTab: 0,
      tabs: [
        {
          title: 'Звіти',
          component: 'DashboardSP',
          dashboardId: 7,
        },
      ],
    }
  },

  computed: {},

  methods: {
    getComponentProps(item) {
      const props = {}
      if (item.dashboardId) {
        props['dashboard-id'] = item.dashboardId
      }
      return props
    },
  },
}
</script>
